import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { SilRoute } from 'shared/types'
import { LocalStorageKeys } from 'shared/utils/keys'

interface Props extends SilRoute {}

const PrivateRoute: FunctionComponent<Props> = ({ component, path, exact, authority = [] }) => {
    const authorityFromLs = localStorage.getItem(LocalStorageKeys.AUTHORITY) || ''
    const userAuthority: string[] = JSON.parse(authorityFromLs) || []
    const userHasRequiredRole = authority.some((el) => userAuthority.indexOf(el) >= 0)

    return userHasRequiredRole ? (
        <Route component={component} exact={exact} path={path} />
    ) : (
        <Redirect to="/unauthorized" />
    )
}

export default PrivateRoute
