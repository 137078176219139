import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { ResponseError } from 'umi-request'

import request from 'shared/api/request'
import { RequestResponse, User } from 'shared/types'

interface FetchResult extends RequestResponse {
    value: User[]
}

type AddParams = {
    userName: string
    institute: string
    password: string
    lockoutEnabled: boolean
}

type RemoveParam = string

type EditParams = {
    id: string
    userName: string
    institute?: string
    lockoutEnabled?: boolean
}

async function fetchUsers(): Promise<User[]> {
    const result = await request
        .get('users')
        .then(({ isSuccessful, value }: FetchResult) => {
            if (isSuccessful) {
                return value
            }
            return []
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

async function postUser(reqBody: AddParams) {
    const result = await request
        .post('users', { data: reqBody })
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

async function putUser(reqBody: EditParams) {
    const result = await request
        .put('users', { data: reqBody })
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}
async function deleteUser(userId: RemoveParam) {
    const result = await request
        .delete(`users/${userId}`)
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

export default function useUsers(): UseQueryResult<User[]> {
    return useQuery('users', fetchUsers, { staleTime: 3600000 })
}

export function useAddUser() {
    const queryClient = useQueryClient()

    return useMutation<RequestResponse, ResponseError<RequestResponse>, AddParams>((data) => postUser(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('users')
        },
    })
}

export function useRemoveUser() {
    const queryClient = useQueryClient()

    return useMutation<RequestResponse, ResponseError<RequestResponse>, RemoveParam>((data) => deleteUser(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('users')
        },
    })
}

export function useEditUser() {
    const queryClient = useQueryClient()

    return useMutation<RequestResponse, ResponseError<RequestResponse>, EditParams>((data) => putUser(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('users')
        },
    })
}
