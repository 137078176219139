import React, { FunctionComponent, useEffect } from 'react'
import { Alert, Button, Form, Input, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import useLogin, { LoginParamsType } from 'shared/hooks/useLogin'
import logo from 'shared/images/white-logo.png'

import { de, en } from './locales'
import styles from './styles.module.less'

type LocationStateType = { from?: string }

interface Props extends RouteComponentProps<never, never, LocationStateType> {}

const Login: FunctionComponent<Props> = ({ history }) => {
    const { t, i18n } = useTranslation('LOGIN')
    i18n.addResourceBundle('en-US', 'LOGIN', en)
    i18n.addResourceBundle('de-DE', 'LOGIN', de)

    const [form] = Form.useForm<LoginParamsType>()
    // const from = location.state && location.state.from ? location.state.from : '/'

    const {
        user: { isLogged },
        login,
        error,
        isError,
        isLoading,
    } = useLogin()
    function handleOnFinish() {
        const { validateFields } = form
        validateFields()
            .then((values) => {
                login({
                    ...values,
                })
            })
            .catch((err) => console.error(err))
    }

    useEffect(() => {
        if (isLogged) {
            console.log('REDIRECTING')
            history.push('/')
        }
    }, [isLogged])

    return (
        <section className={styles.wrapper}>
            <img alt="logo" className={styles.logo} src={logo} />
            <Form form={form} name="login_form" onFinish={handleOnFinish}>
                <Typography.Title level={2}>{t('title')}</Typography.Title>
                <Typography.Title level={4}>{t('description')}</Typography.Title>
                <Form.Item name="userName" rules={[{ required: true, message: t('fields.userName.reqError') }]}>
                    <Input placeholder={t('fields.userName.placeholder')} suffix={<UserOutlined />} />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: t('fields.password.reqError') }]}>
                    <Input.Password placeholder={t('fields.password.placeholder')} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={isLoading} size="large" type="primary">
                        {t('btnLabel')}
                    </Button>
                    {isError && <Alert message={error?.message} showIcon type="error" />}
                </Form.Item>
            </Form>
            <footer className={styles.footer}>&copy; Swiss Insights 2021</footer>
        </section>
    )
}

export default Login
