import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { ResponseError } from 'umi-request'

import request from 'shared/api/request'
import { Institute, RequestResponse } from 'shared/types'

interface FetchResult extends RequestResponse {
    value: Institute[]
}

type AddParams = {
    institute: string
    instituteId: string
    IsActive: boolean
}

type EditParams = Institute

type DeleteParam = string

async function fetchInstitutes(): Promise<Institute[]> {
    const result = await request
        .get('sil/institutes')
        .then(({ isSuccessful, value }: FetchResult) => {
            if (isSuccessful) {
                return value
            }
            return []
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

async function addInstitute(data: AddParams) {
    const result = await request
        .post('sil/institutes', { data })
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })
    return result
}

async function editInstitute(data: EditParams) {
    const result = await request
        .put('sil/institutes', { data })
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })
    return result
}

async function deleteInstitute(instituteId: DeleteParam) {
    const result = await request
        .delete(`sil/institutes/${instituteId}/delete`)
        .then((res) => res)
        .catch((err) => {
            throw new Error(err.message)
        })
    return result
}

export function useAddInstitute() {
    const queryClient = useQueryClient()
    return useMutation<RequestResponse, ResponseError<RequestResponse>, AddParams>((data) => addInstitute(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('institutes')
        },
    })
}

export function useEditInstitute() {
    const queryClient = useQueryClient()

    return useMutation<RequestResponse, ResponseError<RequestResponse>, EditParams>((data) => editInstitute(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('institutes')
            queryClient.invalidateQueries('users')
        },
    })
}

export function useDeleteInstitute() {
    const queryClient = useQueryClient()

    return useMutation<RequestResponse, ResponseError<RequestResponse>, DeleteParam>((id) => deleteInstitute(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('institutes')
            queryClient.invalidateQueries('users')
        },
    })
}

export default function useInstitutes(): UseQueryResult<Institute[]> {
    return useQuery('institutes', fetchInstitutes, { staleTime: 3600000 })
}
