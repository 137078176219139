import React, { FunctionComponent } from 'react'
import { Col, Layout, Row } from 'antd'

import useLogin from 'shared/hooks/useLogin'

import styles from './styles.module.less'
import LangSelector from './LangSelector'
import UserMenu from './UserMenu'

const Header: FunctionComponent = () => {
    const { logout } = useLogin()
    return (
        <Layout.Header
            className={styles.header}
            style={{
                lineHeight: 1,
            }}
        >
            <Row align="middle" justify="space-between">
                <div />
                <Col className={styles.right_panel}>
                    <LangSelector />
                    <div className={styles.buttons_divider} />
                    <UserMenu logout={() => logout()} />
                </Col>
            </Row>
        </Layout.Header>
    )
}

export default Header
