import React, { FunctionComponent, useEffect } from 'react'
import { Alert, Checkbox, Form, Input, message, Modal, Select, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'
import { isEqual } from 'lodash'

import { useEditUser } from 'shared/hooks/useUsers'
import { Institute, User } from 'shared/types'
import { userRoles } from 'shared/utils/keys'

type FormValues = {
    userName: string
    institute: string
    role: string
    lockoutEnabled: boolean
}

interface Props {
    visible: boolean
    userData?: User
    onCancel: () => void
    institutes: Institute[]
}

const formItemConfig = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14, offset: 2 },
}

const EditUser: FunctionComponent<Props> = ({ visible, userData, onCancel, institutes }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [form] = Form.useForm<FormValues>()
    const rules: Rule[] = [{ required: true, message: t('TRANSLATION:validation.required') }]
    const { mutate: editUser, isLoading, error, reset } = useEditUser()

    function handleOnSubmit() {
        form.validateFields().then((values) => {
            reset()

            if (userData) {
                const initialValues = {
                    id: userData.id,
                    userName: userData.userName,
                    role: userData.role,
                    institute: userData.institute || undefined,
                    lockoutEnabled: userData.lockoutEnabled,
                }

                const reqBody = {
                    id: userData.id,
                    userName: values.userName,
                    role: [values.role],
                    institute: values.institute,
                    lockoutEnabled: values.lockoutEnabled,
                }

                if (isEqual(initialValues, reqBody)) {
                    message.warning(t('TRANSLATION:global.errors.noChanges'))
                } else {
                    editUser(reqBody)
                    onCancel()
                }
            }
        })
    }

    useEffect(() => {
        if (userData) {
            const initialValues = {
                userName: userData.userName,
                institute: userData.institute || undefined,
                role: userData.role[0],
                lockoutEnabled: userData.lockoutEnabled,
            }

            form.setFieldsValue(initialValues)
        }
    }, [userData])
    return (
        <Modal
            cancelText={t('TRANSLATION:global.cancel')}
            centered
            destroyOnClose
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            okText={t('TRANSLATION:global.save')}
            onCancel={onCancel}
            onOk={handleOnSubmit}
            title={t('APP:views.usersManagement.editUser.title', { name: userData?.userName || '' })}
            visible={visible}
            width={400}
        >
            <div>
                <Form form={form} labelAlign="left" layout="horizontal" onFinish={handleOnSubmit}>
                    <Form.Item
                        label={t('APP:globals.username')}
                        name="userName"
                        rules={[...rules, { type: 'email', message: t('TRANSLATION:validation.typeEmail') }]}
                        {...formItemConfig}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('APP:globals.role')} name="role" rules={rules} {...formItemConfig}>
                        <Select optionLabelProp="label">
                            {userRoles.map((role) => (
                                <Select.Option key={role} label={t(`APP:globals.${role}`)} value={role}>
                                    <Tag color="red">{t(`APP:globals.${role}`)}</Tag>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('APP:globals.institute')} name="institute" rules={rules} {...formItemConfig}>
                        <Select optionLabelProp="label">
                            {institutes.map((item) => (
                                <Select.Option key={item.instituteId} label={item.instituteId} value={item.instituteId}>
                                    <Tag color="red" style={{ margin: '0 20px 0 0' }}>
                                        {item.instituteId}
                                    </Tag>
                                    {item.institute}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('APP:globals.active')}
                        name="lockoutEnabled"
                        valuePropName="checked"
                        {...formItemConfig}
                    >
                        <Checkbox />
                    </Form.Item>
                </Form>
                {error && <Alert message={error?.message} type="error" />}
            </div>
        </Modal>
    )
}
export default EditUser
