import React, { FunctionComponent } from 'react'
import { Card, PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'

import useStatistic from 'shared/hooks/useStatistic'
import useMe from 'shared/hooks/useUser'

import List from './components/List'
import Info from './components/Info'

const Homepage: FunctionComponent = () => {
    const { t } = useTranslation('APP')
    const { data = [], isFetching } = useStatistic()
    const { userStore } = useMe()
    return (
        <PageHeader
            className="pageHeader-global"
            title={<h1 style={{ margin: 0 }}>{t('APP:components.menu.homepage')}</h1>}
        >
            <Card bordered className="card-global">
                {userStore?.user?.isAdmin ? (
                    <List data={data} isFetching={isFetching} />
                ) : (
                    <Info data={data} loading={isFetching} />
                )}
            </Card>
        </PageHeader>
    )
}

export default Homepage
