import { useState } from 'react'
import { useQueryClient } from 'react-query'

import request from 'shared/api/request'
import { downloadBlob, formatDate } from 'shared/utils/helpers'

type DownloadStatus = 'idle' | 'success' | 'error'
interface HookResult {
    status: DownloadStatus
    loading: boolean
    error?: string
    downloadFile: () => void
}

export default function useDownload(): HookResult {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [status, setStatus] = useState<DownloadStatus>('idle')
    const queryClient = useQueryClient()

    async function downloadFile() {
        setLoading(true)
        setError(undefined)
        setStatus('idle')
        await request
            .get('sil/download')
            .then((response) => {
                const todayDate = new Date()
                const formatedTodayDate = formatDate(todayDate, 'short').replaceAll('.', '-')
                const fileName = `SilList-${formatedTodayDate}.csv`
                const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' })
                setStatus('success')
                downloadBlob(blob, fileName)
                queryClient.invalidateQueries('downloadDate')
                queryClient.invalidateQueries('uploadedFiles')
            })
            .catch((err) => {
                if (err.message) {
                    setError(err.message)
                } else {
                    setError('Problem with Download API')
                }
                setStatus('error')
            })

        setLoading(false)
    }

    return { status, error, loading, downloadFile }
}
