import React from 'react'
import { notification } from 'antd'
import request, { extend } from 'umi-request'

import { identitiyRequest } from 'shared/api/authRequest'
import { LocalStorageKeys } from 'shared/utils/keys'
import i18n from 'locales/i18n'

import DescriptionComponent from './Description'

const { REACT_APP_RESTAPI_URL, REACT_APP_ASPNET_IDENTITY_SECRET } = process.env

const apiReq = extend({
    prefix: REACT_APP_RESTAPI_URL,
    responseType: 'json',
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

type ResposeType = {
    isSuccessful: boolean
    message: string
    stackTrace: string | null
    exceptionDetails: string | null
}

apiReq.interceptors.request.use(
    (url, options) => {
        const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
        let authHeader
        if (token !== null) {
            authHeader = {
                Authorization: `Bearer ${token}`,
            }
            return {
                url,
                options: { ...options, headers: { ...options.headers, ...authHeader } },
            }
        }
        return {
            url,
            options: { ...options, interceptors: true },
        }
    },
    { global: false }
)

apiReq.interceptors.response.use(
    async (response, options) => {
        const { status, url, statusText } = response
        const contentType = response.headers.get('Content-Type')
        const isJson = contentType ? contentType.indexOf('application/json') >= 0 : false

        if (status === 401) {
            const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN)
            const sendData = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=Link.Alpha.OptiMach.Web&client_secret=${REACT_APP_ASPNET_IDENTITY_SECRET}`

            notification.info({
                message: i18n.t(`TRANSLATION:requestInfos.refreshingTokenTitle`),
                description: i18n.t(`TRANSLATION:requestInfos.refreshingToken`),
            })

            return identitiyRequest
                .post('connect/token', { data: sendData })
                .then((res) => {
                    if (res.access_token) {
                        // 1) put token to LocalStorage
                        const expirationDate = new Date(new Date().getTime() + res.expires_in * 1000)
                        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, res.access_token)
                        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, res.refresh_token)
                        localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)
                        // 2) Change Authorization header
                        const authHeader = {
                            Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)}`,
                        }
                        // 3) return originalRequest object with Axios.
                        return request(url, {
                            method: options.method,
                            responseType: options.responseType,
                            headers: { ...options.headers, ...authHeader },
                        })
                    }
                    return Promise.reject(Error('Ups something went wrong !!!'))
                })
                .catch((err) => {
                    notification.error({
                        message: i18n.t(`TRANSLATION:requestErrors.refreshingTokenTitle`),
                        description: err.message,
                    })
                })
        }
        // if status is different than 200 so return statusCode error from codeMessage but if status === 200 then we need to implement business layer and test if is Successfull === true/false
        if (status !== 200) {
            const errorMsg = i18n.t(`TRANSLATION:requestErrors.statusCode.${status}`)
            notification.error({
                message: i18n.t(`TRANSLATION:requestErrors.notificationTitle`),
                description: React.createElement(DescriptionComponent, {
                    message: errorMsg,
                    details: `Status code: ${status} | Req url: ${url} | Status text: ${statusText}`,
                }),
            })
            if (isJson) {
                const parsedResponse = await response.clone().json()
                if (typeof parsedResponse === 'string') {
                    return Promise.reject(Error(parsedResponse))
                }
            }
            return Promise.reject(Error(errorMsg))
        }

        // check if response is json

        if (isJson) {
            const data: ResposeType = await response.clone().json()
            if (data.isSuccessful && data.message) {
                const splittedMessage = data.message.split('|')
                const paramsMessage = Object.assign(
                    {},
                    ...splittedMessage.slice(1).map((param, index) => ({ [index + 1]: param }))
                )
                const infoMsg = i18n.t(`TRANSLATION:requestInfos.${splittedMessage[0]}`, paramsMessage) || data.message
                notification.success({
                    message: i18n.t(`TRANSLATION:requestInfos.notificationTitle`),
                    description: React.createElement(DescriptionComponent, {
                        message: infoMsg,
                        details: '',
                    }),
                })
            }
            if (!data.isSuccessful) {
                const splittedMessage = data.message.split('|')
                const paramsMessage = Object.assign(
                    {},
                    ...splittedMessage.slice(1).map((param, index) => ({ [index + 1]: param }))
                )
                const errorMsg =
                    i18n.t(`TRANSLATION:requestErrors.${splittedMessage[0]}`, paramsMessage) || data.message
                notification.error({
                    message: i18n.t(`TRANSLATION:requestErrors.notificationTitle`),
                    description: React.createElement(DescriptionComponent, {
                        message: errorMsg,
                        details: data.exceptionDetails || '',
                    }),
                })
                return Promise.reject(Error(errorMsg))
            }
        }

        return response
    },
    { global: false }
)

export default apiReq
