import { LoadingOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

interface Props {
    status: MutationStatus
}

const QueryStatus: FunctionComponent<Props> = ({ status }) => {
    const { t } = useTranslation('TRANSLATION')
    switch (status) {
        case 'loading':
            return (
                <Tag color="blue">
                    {t('global.success')} <LoadingOutlined />
                </Tag>
            )
        case 'success':
            return <Tag color="success">{t('global.successFile')}</Tag>
        case 'error':
            return <Tag color="error">{t('global.error')}</Tag>
        default:
            return <Tag>{t('global.idle')}</Tag>
    }
}

export default QueryStatus
