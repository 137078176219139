import React, { FunctionComponent, useRef } from 'react'
import { Button, Input, Progress, Table, Tag } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { SearchOutlined } from '@ant-design/icons/lib/icons'
import { useTranslation } from 'react-i18next'

import { InstituteStats } from 'shared/types'
import { formatDateTime, formatNumber, sortAlphabetical, sortByDate, sortNumber } from 'shared/utils/helpers'

import styles from './styles.module.less'

interface Props {
    data: InstituteStats[]
    isFetching: boolean
}
const List: FunctionComponent<Props> = ({ data, isFetching }) => {
    const { t } = useTranslation('APP')
    const searchInput: React.RefObject<Input> = useRef(null)

    function getColumnSearchProps(dataIndex: keyof InstituteStats) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onChange={(e) => {
                            setSelectedKeys(e ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm({ closeDropdown: false })}
                        ref={searchInput}
                        style={{ width: 190, marginBottom: 10, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        disabled={!data.length}
                        icon={<SearchOutlined />}
                        onClick={() => confirm({ closeDropdown: false })}
                        size="small"
                        style={{ marginRight: 10 }}
                        type="primary"
                    >
                        {t('TRANSLATION:global.search')}
                    </Button>
                    <Button
                        disabled={!data.length}
                        onClick={() => {
                            if (clearFilters) clearFilters()
                        }}
                        size="small"
                    >
                        {t('TRANSLATION:global.reset')}
                    </Button>
                </div>
            ),

            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#005fb2' : undefined }} />,

            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput?.current?.select(), 100)
                }
            },

            onFilter: (value: string | number | boolean, record: InstituteStats) => {
                const recordValue = record[dataIndex]
                if (recordValue) {
                    return recordValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    return (
        <Table<InstituteStats>
            bordered
            dataSource={data}
            loading={isFetching}
            pagination={false}
            rowKey="instituteId"
            scroll={{ x: 'max-content', y: 'max-content' }}
            summary={(records) => {
                const totalCount = records.reduce((acc, item) => acc + item.count, 0)
                const totalPercent = records.reduce((acc, item) => acc + item.percentageShare, 0)
                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell align="center" colSpan={3} index={0}>
                            <h2 style={{ margin: 0 }}>{t('APP:globals.total')}</h2>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right" index={2}>
                            <Tag color="red" style={{ margin: 0, fontSize: '14px', fontWeight: 'bold', lineHeight: 2 }}>
                                {formatNumber(totalCount)}
                            </Tag>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="center" index={3}>
                            <Tag color="red" style={{ margin: 0, fontSize: '14px', fontWeight: 'bold', lineHeight: 2 }}>
                                {`${totalPercent.toFixed(0)} %`}
                            </Tag>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                )
            }}
        >
            {/* <Table.Column<InstituteStats>
                align="center"
                render={(_, record, index) => index + 1}
                title="ID"
                width={50}
            /> */}
            <Table.Column<InstituteStats>
                align="center"
                dataIndex="instituteId"
                key="instituteId"
                render={(_, { instituteId }) => <Tag color="red">{instituteId}</Tag>}
                sorter={(a, b) => sortAlphabetical(a.instituteId, b.instituteId)}
                title={t('APP:globals.instituteId')}
                width={80}
            />
            <Table.Column<InstituteStats>
                align="center"
                dataIndex="institute"
                key="institute"
                render={(_, { institute }) => institute}
                sorter={(a, b) => sortAlphabetical(a.institute, b.institute)}
                title={t('APP:globals.institute')}
                width={150}
                {...getColumnSearchProps('institute')}
            />
            <Table.Column<InstituteStats>
                align="center"
                dataIndex="lastReportedDate"
                defaultSortOrder="descend"
                key="lastReportedDate"
                render={(_, { lastReportedDate }) => formatDateTime(lastReportedDate, 'long')}
                sorter={(a, b) => sortByDate(a.lastReportedDate, b.lastReportedDate)}
                title={t('views.homepage.table.lastReportedDate')}
                width={150}
            />
            <Table.Column<InstituteStats>
                align="right"
                dataIndex="count"
                key="count"
                render={(_, { count }) => formatNumber(count)}
                sorter={(a, b) => sortNumber(a.count, b.count)}
                title={t('views.homepage.table.count')}
                width={120}
            />
            <Table.Column<InstituteStats>
                align="center"
                dataIndex="percentageShare"
                key="percentageShare"
                render={(_, { percentageShare }) => {
                    return (
                        <Progress
                            className={styles.progressBar}
                            percent={parseFloat(percentageShare.toFixed(1))}
                            strokeLinecap="square"
                        />
                    )
                }}
                sorter={(a, b) => sortNumber(a.percentageShare, b.percentageShare)}
                title={t('views.homepage.table.percentageShare')}
                width={300}
            />
        </Table>
    )
}

export default List
