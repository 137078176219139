import { Card, Col, Row, Tag } from 'antd'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { LoginUser } from 'shared/types'

import styles from './styles.module.less'

interface Props {
    user?: LoginUser
}

const Info: FunctionComponent<Props> = ({ user }) => {
    const { t } = useTranslation('APP')

    return (
        <Card style={{ marginBottom: 20 }} type="inner">
            <div className={styles.infoWrapper}>
                <Row align="middle">
                    <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                        <h3>{t('APP:globals.instituteId')}:</h3>
                    </Col>
                    <Col>
                        <Tag color="red">{user?.institute}</Tag>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                        <h3>{t('APP:globals.institute')}:</h3>
                    </Col>
                    <Col>
                        {' '}
                        <Tag color="red">{user?.instituteName}</Tag>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                        <h3>{t('APP:globals.role')}:</h3>
                    </Col>
                    <Col>
                        <Tag color="red">{t(`globals.${user?.role}`)}</Tag>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default Info
