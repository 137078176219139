import React, { FunctionComponent, useState } from 'react'
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutateFunction } from 'react-query'

import { UploadedFileInfo } from 'shared/hooks/useUploadMutation'
import { formatDateTime } from 'shared/utils/helpers'

import styles from './styles.module.less'

interface Props {
    onUpload: UseMutateFunction<UploadedFileInfo, unknown, FormData, unknown>
    onNoUpload: UseMutateFunction
    onResetQuery: () => void
    onUpdateReport: (fileInfo?: UploadedFileInfo) => void
    loading: boolean
    lastUploadDate?: Date
}

const UploadComponent: FunctionComponent<Props> = ({
    onUpload,
    onResetQuery,
    onUpdateReport,
    lastUploadDate,
    loading,
    onNoUpload,
}) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [fileList, setFileList] = useState<RcFile[]>([])
    const lastUpdate = lastUploadDate ? formatDateTime(lastUploadDate) : t('TRANSLATION:global.unknown')

    function handleBeforeUpload(file: RcFile) {
        const newFileList = [file]
        setFileList(newFileList)
        return false
    }

    function handleOnRemove() {
        setFileList([])
    }

    async function handleOnUpload() {
        const formData = new FormData()
        formData.append('File', fileList[0])
        onResetQuery()
        onUpdateReport(undefined)
        onUpload(formData, {
            onSuccess: (data) => {
                setFileList([])
                onUpdateReport(data)
            },
        })
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <InfoCircleOutlined />
                <h3>
                    <Trans i18nKey="APP:views.uploadFile.upload.infoText">
                        Your last upload was on {{ lastUpdate }}
                    </Trans>
                </h3>
            </div>
            <div className={styles.uploadButtons}>
                <Upload
                    accept=".csv,.txt"
                    beforeUpload={handleBeforeUpload}
                    className={styles.uploadComponent}
                    fileList={fileList}
                    listType="picture"
                    maxCount={1}
                    onRemove={handleOnRemove}
                >
                    <Button icon={<UploadOutlined />}>{t('APP:views.uploadFile.upload.placeholder')}</Button>
                </Upload>
                <Button loading={loading} onClick={() => onNoUpload()} type="primary">
                    {t('TRANSLATION:global.noUpload')}
                </Button>
            </div>
            <Button
                disabled={fileList.length === 0}
                onClick={handleOnUpload}
                style={{ marginTop: '20px', minWidth: 155 }}
                type="primary"
            >
                {t('TRANSLATION:global.upload')}
            </Button>
        </div>
    )
}

export default UploadComponent
