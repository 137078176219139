import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import 'locales/i18n'
import 'shared/styles/main.less'

import App from 'views/App'
import Login from 'views/Login'

import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Switch>
                    <Route component={Login} path="/login" />
                    <Route component={App} path="/" />
                </Switch>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
    </React.StrictMode>,

    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
