import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, CheckOutlined, GlobalOutlined } from '@ant-design/icons'

import i18n from 'locales/i18n'
import { locales } from 'config/languages'

import styles from './styles.module.less'

export default function LangSelector(): JSX.Element {
    const initialLang = i18n.language
    const [selectedLang, setSelectedLand] = useState<string>(initialLang)
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

    function selectLang(key: string) {
        i18n.changeLanguage(key, () => {
            setSelectedLand(key)
            setDropdownVisible(false)
        })
    }

    return (
        <Dropdown
            onVisibleChange={() => setDropdownVisible(!dropdownVisible)}
            overlay={
                <ul className={styles.lang_menu}>
                    {locales.map(({ prefix, name }) => (
                        <li className={styles.lang_menuItem} data-active={selectedLang === prefix} key={prefix}>
                            <button onClick={() => selectLang(prefix)} type="button">
                                <span aria-label={name} role="img">
                                    {name}
                                </span>
                                <CheckOutlined />
                            </button>
                        </li>
                    ))}
                </ul>
            }
            placement="bottomRight"
            trigger={['click']}
            visible={dropdownVisible}
        >
            <button className={styles.headerBtn} type="button">
                <GlobalOutlined className={styles.headerBtn_avatar} />
                {/* <span className={styles.headerBtn_label}>{LANG && LANG.name}</span> */}
                {dropdownVisible ? (
                    <CaretUpOutlined className={styles.headerBtn_caret} />
                ) : (
                    <CaretDownOutlined className={styles.headerBtn_caret} />
                )}
            </button>
        </Dropdown>
    )
}
