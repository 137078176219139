import { useMutation, useQueryClient } from 'react-query'
import { ResponseError } from 'umi-request'

import request from 'shared/api/request'
import { RequestResponse } from 'shared/types'

type ErrorMsg = {
    message: string
}

export type UploadedFileInfo = {
    id: number
    ok: number
    errors: number
    csvFileName: string
}
interface UploadResponse extends RequestResponse {
    value: UploadedFileInfo
}
interface NoUploadResponse extends RequestResponse {
    value: string
}

async function sendNoUploadReq() {
    const response = await request
        .post('sil/noupload')
        .then((res: NoUploadResponse) => {
            if (res.isSuccessful) {
                return res.value
            }
            throw new Error(res.message || 'Problem with Uploaded file')
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return response
}

async function uploadFile(file: FormData) {
    const response = await request
        .post('sil/upload', { data: file })
        .then((res: UploadResponse) => {
            if (res.isSuccessful) {
                return res.value
            }
            throw new Error(res.message || 'Problem with Uploaded file')
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return response
}

export function useNoUploadMutation() {
    const queryClient = useQueryClient()
    return useMutation<string, ResponseError<ErrorMsg>>(sendNoUploadReq, {
        onSuccess: () => {
            queryClient.invalidateQueries('uploadedFiles')
            queryClient.invalidateQueries('instituteStats')
        },
    })
}

export default function useUploadMutation() {
    const queryClient = useQueryClient()
    return useMutation<UploadedFileInfo, ResponseError<ErrorMsg>, FormData>((file) => uploadFile(file), {
        onSuccess: () => {
            queryClient.invalidateQueries('uploadedFiles')
            queryClient.invalidateQueries('instituteStats')
        },
    })
}
