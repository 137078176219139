import React from 'react'
import { Alert, Button, Card, PageHeader, Result, Spin, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

import useDownload from 'shared/hooks/useDownload'
import useDownloadDate from 'shared/hooks/useDownloadDate'
import { formatDateTime } from 'shared/utils/helpers'

import styles from './styles.module.less'

const DownloadFile: React.FunctionComponent = () => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])

    const { status, error, loading, downloadFile } = useDownload()
    const { data } = useDownloadDate()
    const formatedDate = data ? formatDateTime(data, 'long') : ''
    const isSuccess = status === 'success'

    return (
        <PageHeader
            className={`${styles.center} pageHeader-global`}
            title={<h1 style={{ margin: 0 }}>{t('APP:components.menu.downloadfile')}</h1>}
        >
            <Card bordered className="card-global">
                <Result
                    extra={
                        <div className={styles.extra}>
                            {isSuccess && <Tag color="success">{t('APP:views.downloadFile.successMsg')}</Tag>}
                            {loading && <Spin />}
                            {error && <Alert message={error} style={{ marginTop: 10 }} type="error" />}
                            <Button disabled={loading} loading={loading} onClick={downloadFile} type="primary">
                                {t('APP:views.downloadFile.downloadBtn')}
                            </Button>
                        </div>
                    }
                    status={status === 'idle' ? 'info' : status}
                    title={
                        <div className={styles.resultTitle}>
                            {t('APP:views.downloadFile.header', { date: formatedDate })}
                        </div>
                    }
                />
            </Card>
        </PageHeader>
    )
}

export default DownloadFile
