import React, { FunctionComponent } from 'react'
import { Layout, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import buildData from 'version.json'

import appRoutes from 'config/routes'
import { SilRoute } from 'shared/types'
import { LocalStorageKeys } from 'shared/utils/keys'
import logo from 'shared/images/logo.png'
import { formatDateTime } from 'shared/utils/helpers'

import styles from './styles.module.less'
import Header from '../Header'
import Footer from '../Footer'

interface Props {
    path: string
    children: React.ReactNode
}

const LayoutComponent: FunctionComponent<Props> = ({ children, path }) => {
    const { t } = useTranslation('APP')

    function renderMenuItem(route: SilRoute) {
        const { path: routePath, icon, name, hideInMenu, authority: routeAuthority = [] } = route
        const authorityFromLs = localStorage.getItem(LocalStorageKeys.AUTHORITY) || ''
        const userAuthority: string[] = JSON.parse(authorityFromLs) || []
        const userHasRequiredRole = routeAuthority.length
            ? routeAuthority.some((el) => userAuthority.indexOf(el) >= 0)
            : true

        return !hideInMenu && userHasRequiredRole ? (
            <Menu.Item icon={icon} key={routePath}>
                <Link to={routePath}>{t(`components.menu.${name}`)}</Link>
            </Menu.Item>
        ) : null
    }
    return (
        <Layout>
            <Layout.Sider
                breakpoint="md"
                // collapsed={collapsed}
                collapsedWidth="80"
                // collapsible={false}
                // onBreakpoint={(broken) => {
                //     console.log(broken)
                // }}
                theme="light"
                trigger={null}
                width={300}
            >
                <div className={styles.sider_wrapper}>
                    <Link className={styles.logo} to="/">
                        <img alt="" src={logo} />
                    </Link>
                    <Menu mode="vertical-left" selectedKeys={[path]}>
                        {appRoutes.map((route) => renderMenuItem(route))}
                    </Menu>
                    <span>
                        Version #{buildData.commit} Build #{buildData.build}
                        <br />
                        {formatDateTime(new Date(buildData.dateTime))}
                    </span>
                </div>
            </Layout.Sider>
            <Layout>
                <Header />
                <Layout.Content className={styles.content}>{children}</Layout.Content>
                <Footer />
            </Layout>
        </Layout>
    )
}

export default LayoutComponent
