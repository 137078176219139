import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

export default function NotFound(): JSX.Element {
    return (
        <Result
            extra={
                <Link to="/" type="primary">
                    Back Home
                </Link>
            }
            status="404"
            subTitle="Sorry, the page you visited does not exist."
            title="404"
        />
    )
}
