import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
    message: string
    details: string
}

export default function Description({ message, details }: Props): JSX.Element {
    const { t } = useTranslation('TRANSLATION')
    return (
        <div>
            <Typography.Text copyable={{ text: details }}>{t('requestErrors.notificationCopy')}</Typography.Text>
            <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }} style={{ fontSize: '16px' }} type="danger">
                {message}
            </Typography.Paragraph>
        </div>
    )
}
