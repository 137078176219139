import React, { FunctionComponent, useRef, useState } from 'react'
import { Button, Input, Table, Tag } from 'antd'
import { FileDoneOutlined, FileExclamationOutlined, SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { FilterDropdownProps } from 'antd/lib/table/interface'

import { UploadedFile } from 'shared/types'
import request from 'shared/api/request'
import { downloadBlob, formatTime, sortAlphabetical, sortByDate, sortNumber } from 'shared/utils/helpers'

import styles from './styles.module.less'

interface Props {
    isFetching: boolean
    list: UploadedFile[]
}

const UploadList: FunctionComponent<Props> = ({ isFetching, list }) => {
    const { t } = useTranslation('APP')
    const [loading, setLoading] = useState(false)
    const searchInput: React.RefObject<Input> = useRef(null)

    async function handleDownloadDocument(fileInfo: UploadedFile, type: 'original' | 'log') {
        const { id, csvFileName } = fileInfo
        const url = type === 'original' ? `sil/originalfile/${id}` : `sil/logfile/${id}`

        setLoading(true)
        await request
            .get(url)
            .then((response) => {
                if (response) {
                    const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' })
                    if (type === 'log') {
                        const indexOfDot = csvFileName.lastIndexOf('.')
                        const filename = [csvFileName.slice(0, indexOfDot), '-log', csvFileName.slice(indexOfDot)].join(
                            ''
                        )
                        downloadBlob(blob, filename)
                        return
                    }
                    downloadBlob(blob, csvFileName)
                }
            })
            .catch((err) => console.log('ERROR FROM DOWNLOAD FILE', err))
        setLoading(false)
    }

    function getColumnSearchProps(dataIndex: keyof UploadedFile) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onChange={(e) => {
                            setSelectedKeys(e ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm({ closeDropdown: false })}
                        ref={searchInput}
                        style={{ width: 190, marginBottom: 10, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        disabled={!list.length}
                        icon={<SearchOutlined />}
                        onClick={() => confirm({ closeDropdown: false })}
                        size="small"
                        style={{ marginRight: 10 }}
                        type="primary"
                    >
                        {t('TRANSLATION:global.search')}
                    </Button>
                    <Button
                        disabled={!list.length}
                        onClick={() => {
                            if (clearFilters) clearFilters()
                        }}
                        size="small"
                    >
                        {t('TRANSLATION:global.reset')}
                    </Button>
                </div>
            ),

            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#005fb2' : undefined }} />,

            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput?.current?.select(), 100)
                }
            },

            onFilter: (value: string | number | boolean, record: UploadedFile) => {
                const recordValue = record[dataIndex]
                if (recordValue) {
                    return recordValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    return (
        <Table<UploadedFile>
            bordered
            dataSource={list}
            loading={isFetching}
            rowKey="id"
            scroll={{ x: 'max-content', y: 'max-content' }}
        >
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="upDownDate"
                key="upDownDate"
                render={(_, { upDownDate }) => formatTime(upDownDate)}
                sorter={(a, b) => sortByDate(a.upDownDate, b.upDownDate)}
                title={t('APP:globals.upDownDate')}
                width={150}
            />
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="csvFileName"
                key="csvFileName"
                render={(_, { csvFileName }) => csvFileName}
                sorter={(a, b) => sortAlphabetical(a.csvFileName, b.csvFileName)}
                title={t('APP:globals.csvFileName')}
                width={200}
                {...getColumnSearchProps('csvFileName')}
            />
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="ok"
                key="ok"
                render={(_, { ok }) => <Tag color="success">{ok}</Tag>}
                sorter={(a, b) => sortNumber(a.ok, b.ok)}
                title={t('APP:globals.ok')}
                width={100}
            />
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="errors"
                key="errors"
                render={(_, { errors }) => <Tag color="error">{errors}</Tag>}
                sorter={(a, b) => sortNumber(a.errors, b.errors)}
                title={t('APP:globals.errors')}
                width={100}
            />
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="total"
                key="total"
                render={(_, { total }) => <Tag>{total}</Tag>}
                sorter={(a, b) => sortNumber(a.total, b.total)}
                title={t('APP:globals.total')}
                width={100}
            />
            <Table.Column<UploadedFile>
                align="center"
                dataIndex="actions"
                key="actions"
                render={(_, record) => (
                    <div className={styles.actions}>
                        <Button
                            disabled={loading}
                            icon={<FileDoneOutlined />}
                            loading={loading}
                            onClick={() => handleDownloadDocument(record, 'original')}
                            size="small"
                            title={t('APP:globals.docBtnTitle')}
                            type="primary"
                        >
                            {t('APP:globals.docBtnLabel')}
                        </Button>
                        <Button
                            disabled={loading}
                            icon={<FileExclamationOutlined />}
                            loading={loading}
                            onClick={() => handleDownloadDocument(record, 'log')}
                            size="small"
                            title={t('APP:globals.logBtnTitle')}
                            type="primary"
                        >
                            {t('APP:globals.logBtnLabel')}
                        </Button>
                    </div>
                )}
                title={t('APP:globals.actions')}
                width={220}
            />
        </Table>
    )
}

export default UploadList
