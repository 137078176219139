import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import request from 'shared/api/request'
import { RequestResponse } from 'shared/types'

import { StateType } from './useUser'

interface FetchResult extends RequestResponse {
    value: Date
}

async function updateDate(instituteId: string): Promise<Date | undefined> {
    const response = await request
        .get(`sil/institutes/${instituteId}/last-update`)
        .then((res) => {
            const { isSuccessful, value }: FetchResult = res
            if (isSuccessful) {
                return value
            }
            return undefined
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return response
}

export default function useDownloadDate(): UseQueryResult<Date> {
    const queryClient = useQueryClient()
    const userStore = queryClient.getQueryData<StateType>('user') || { user: undefined, isLogged: false }
    let instituteId = ''
    if (userStore.user) {
        instituteId = userStore.user.institute
    }

    return useQuery('downloadDate', () => updateDate(instituteId), { staleTime: 3600000, enabled: !!instituteId })
}
