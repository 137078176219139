import { Card, Tag } from 'antd'
import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useDownloadDate from 'shared/hooks/useDownloadDate'
import useMe from 'shared/hooks/useUser'
import { InstituteStats } from 'shared/types'
import { formatDateTime } from 'shared/utils/helpers'

import styles from './styles.module.less'
import UploadedFilesList from './UploadedFilesList'

interface Props {
    data: InstituteStats[]
    loading: boolean
}

const Info: FunctionComponent<Props> = ({ data: [firstElem], loading }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])

    const { userStore } = useMe()
    const { data: lastUpdateOfSiList } = useDownloadDate()
    const lastUpdate = lastUpdateOfSiList ? formatDateTime(lastUpdateOfSiList, 'long') : t('TRANSLATION:global.unknown')
    const totalCounts = firstElem ? ((firstElem.count * 100) / firstElem.percentageShare).toFixed() : 'umknown'
    const contributions = firstElem?.count
    const lastReportedDate = firstElem?.lastReportedDate
        ? formatDateTime(firstElem?.lastReportedDate, 'long')
        : t('TRANSLATION:global.unknown')
    return (
        <>
            <Card className={styles.infoCard} loading={loading}>
                <h2>{t('APP:views.homepage.info.welcome', { name: userStore?.user?.userName })}</h2>
                <h3>
                    <Trans i18nKey="APP:views.homepage.info.content">
                        Your Institute has contributed <Tag color="red">{{ contributions }}</Tag> phone numbers to SI
                        List.
                        <span className={styles.heading}>Thank you.</span> Your last upload was on
                        <Tag color="red" style={{ margin: 0 }}>
                            {{ lastReportedDate }}
                        </Tag>
                        . The SI List currently holds
                        <Tag color="red" style={{ margin: 0 }}>
                            {{ totalCounts }}
                        </Tag>
                        total records and was updated on
                        <Tag color="red" style={{ margin: 0 }}>
                            {{ lastUpdate }}
                        </Tag>
                        .
                    </Trans>
                </h3>
            </Card>
            <UploadedFilesList />
        </>
    )
}

export default Info
