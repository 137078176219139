import React, { FunctionComponent, useEffect } from 'react'
import { Alert, Button, Card, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { useQueryClient } from 'react-query'

import { LoginUser } from 'shared/types'
import { useEditUser } from 'shared/hooks/useUsers'

type FormValues = {
    userName: string
    password: string
    confirm: string
}

interface Props {
    user?: LoginUser
}

const labelConfig = {
    labelCol: { xs: { span: 24 }, lg: { span: 11 } },
    wrapperCol: { xs: { span: 24 }, lg: { span: 13 } },
}

const EditForm: FunctionComponent<Props> = ({ user }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [form] = Form.useForm<FormValues>()
    const rules = [{ required: true, message: t('TRANSLATION:validation.required') }]
    const queryClient = useQueryClient()
    const { mutate, isLoading, isError, error, isSuccess, reset } = useEditUser()

    useEffect(() => {
        if (user) {
            form.setFieldsValue({ userName: user.userName })
        }
    }, [user])

    function handleOnSubmit() {
        form.validateFields().then((values) => {
            const initialValues = {
                userName: user?.userName,
                password: undefined,
                confirm: undefined,
            }

            if (isEqual(initialValues, values)) {
                message.warning('Form is not changed')
            } else if (user) {
                const reqBody = {
                    id: user.id,
                    userName: values.userName,
                    password: values.password,
                }

                reset()
                mutate(reqBody, {
                    onSuccess: () => {
                        queryClient.invalidateQueries('user')
                        form.resetFields(['password', 'confirm'])
                    },
                })
            }

            console.log(values)
        })
    }

    return (
        <Card loading={isLoading} style={{ marginBottom: 20 }} type="inner">
            <Form form={form} labelAlign="left" layout="horizontal" onFinish={handleOnSubmit}>
                <Form.Item
                    label={t('APP:globals.username')}
                    name="userName"
                    rules={[...rules, { type: 'email', message: t('TRANSLATION:validation.typeEmail') }]}
                    {...labelConfig}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={t('APP:globals.password')}
                    name="password"
                    rules={[
                        {
                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                            message: t('TRANSLATION:validation.typePassword'),
                        },
                    ]}
                    {...labelConfig}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    dependencies={['password']}
                    hasFeedback
                    label={t('APP:views.userDashboard.labels.confirmPassword')}
                    name="confirm"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(Error('The two passwords that you entered do not match!'))
                            },
                        }),
                    ]}
                    {...labelConfig}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                    <Button htmlType="submit" type="primary">
                        {t(`TRANSLATION:global.save`)}
                    </Button>
                </Form.Item>
                {isError && <Alert message={t(`TRANSLATION:requestErrors.${error}`)} showIcon type="error" />}
                {isSuccess && <Alert message={t('APP:views.userDashboard.successMessage')} showIcon type="success" />}
            </Form>
        </Card>
    )
}

export default EditForm
