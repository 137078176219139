import React from 'react'
import { Layout } from 'antd'
import { Trans } from 'react-i18next'

export default function FooterComponent(): JSX.Element {
    return (
        <Layout.Footer style={{ textAlign: 'center' }}>
            <Trans i18nKey="APP:components.footer.copy">
                SIL Platform © created by <b>LINK</b>
            </Trans>
        </Layout.Footer>
    )
}
