import React, { FunctionComponent, useRef, useState } from 'react'
import { Button, Card, Input, Modal, PageHeader, Table, Tag } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import useInstitutes from 'shared/hooks/useInstitutes'
import useUsers, { useRemoveUser } from 'shared/hooks/useUsers'
import { User } from 'shared/types'
import { getAllNames, sortAlphabetical, sortBoolean } from 'shared/utils/helpers'

import AddInstitute from './components/AddInstitute'
import AddUser from './components/AddUser'
import EditUser from './components/EditUser'
import styles from './styles.module.less'

const UsersManagement: FunctionComponent = () => {
    const { i18n, t } = useTranslation(['TRANSLATION', 'APP'])
    const { data: users = [], isFetching: fetchingUsers } = useUsers()
    const { mutate: removeUser } = useRemoveUser()
    const { data: institutes = [] } = useInstitutes()
    const [instituteModal, setInstituteModal] = useState(false)
    const [userModal, setUserModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User>()
    const searchInput: React.RefObject<Input> = useRef(null)

    function renderInstitute(institute: string | null) {
        if (institute) {
            return <Tag color="red">{institute}</Tag>
        }
        return <Tag color="warning">{t('TRANSLATION:global.errors.empty')}</Tag>
    }

    function renderActive(isActive: boolean) {
        if (isActive) {
            return (
                <Tag color="success" style={{ margin: 0 }}>
                    {t('APP:globals.active')}
                </Tag>
            )
        }
        return (
            <Tag color="error" style={{ margin: 0 }}>
                {t('APP:globals.inactive')}
            </Tag>
        )
    }

    function confirmRemoveUser(record: User) {
        Modal.confirm({
            centered: true,
            cancelText: t('TRANSLATION:global.no'),
            okText: t('TRANSLATION:global.yes'),
            onOk: () => removeUser(record.id),
            title: t('APP:views.usersManagement.warnings.confirmRemove', {
                name: record.userName,
            }),
        })
    }

    function generateFilters(propertyName: keyof User) {
        const allValues = getAllNames<User>(users, propertyName)
        if (allValues.length) {
            return allValues.map((key) => {
                const tKey = `APP:globals.${key}`
                return {
                    text: i18n.exists(tKey) ? t(tKey) : key,
                    value: key,
                }
            })
        }

        return []
    }

    function getFilterProps(dataIndex: keyof User) {
        return {
            filters: generateFilters(dataIndex),
            onFilter: (value: string | number | boolean, record: User) => {
                const recordValue = record[dataIndex]?.toString()
                if (recordValue) {
                    return recordValue.toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    function getColumnSearchProps(dataIndex: keyof User) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onChange={(e) => {
                            setSelectedKeys(e ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm({ closeDropdown: false })}
                        ref={searchInput}
                        style={{ width: 190, marginBottom: 10, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        disabled={!users.length}
                        icon={<SearchOutlined />}
                        onClick={() => confirm({ closeDropdown: false })}
                        size="small"
                        style={{ marginRight: 10 }}
                        type="primary"
                    >
                        {t('TRANSLATION:global.search')}
                    </Button>
                    <Button
                        disabled={!users.length}
                        onClick={() => {
                            if (clearFilters) clearFilters()
                        }}
                        size="small"
                    >
                        {t('TRANSLATION:global.reset')}
                    </Button>
                </div>
            ),

            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#005fb2' : undefined }} />,

            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput?.current?.select(), 100)
                }
            },

            onFilter: (value: string | number | boolean, record: User) => {
                const recordValue = record[dataIndex]
                if (recordValue) {
                    return recordValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    return (
        <PageHeader
            className="pageHeader-global"
            title={<h1 style={{ margin: 0 }}>{t('APP:components.menu.usersManagement')}</h1>}
        >
            <Card
                bordered
                className="card-global"
                title={
                    <div className={styles.titleActions}>
                        <Button
                            icon={<UserAddOutlined />}
                            onClick={() => setUserModal(true)}
                            style={{ marginRight: 20 }}
                            type="primary"
                        >
                            {t('APP:views.usersManagement.labels.addUserBtn')}
                        </Button>
                        <Button icon={<AppstoreAddOutlined />} onClick={() => setInstituteModal(true)} type="primary">
                            {t('APP:views.usersManagement.labels.addInstituteBtn')}
                        </Button>
                    </div>
                }
            >
                <Table<User>
                    dataSource={users}
                    loading={fetchingUsers}
                    rowKey="id"
                    scroll={{ x: 'max-content', y: 'max-content' }}
                >
                    <Table.Column<User>
                        align="center"
                        dataIndex="userName"
                        key="userName"
                        render={(_, { userName }) => userName}
                        sorter={(a, b) => sortAlphabetical(a.userName, b.userName)}
                        title={t('APP:globals.username')}
                        width={150}
                        {...getColumnSearchProps('userName')}
                    />
                    <Table.Column<User>
                        align="center"
                        dataIndex="role"
                        key="role"
                        render={(_, { role }) => (
                            <Tag color={role[0] === 'SILAdmin' ? 'gold' : 'geekblue'}>{t(`APP:globals.${role}`)}</Tag>
                        )}
                        sorter={(a, b) => sortAlphabetical(a.role.toString(), b.role.toString())}
                        title={t('APP:globals.role')}
                        width={80}
                        {...getFilterProps('role')}
                    />
                    <Table.Column<User>
                        align="center"
                        dataIndex="institute"
                        key="institute"
                        render={(_, { institute }) => renderInstitute(institute)}
                        sorter={(a, b) => sortAlphabetical(a.institute, b.institute)}
                        title={t('APP:globals.institute')}
                        width={80}
                    />
                    <Table.Column<User>
                        align="center"
                        dataIndex="lockoutEnabled"
                        key="lockoutEnabled"
                        render={(_, { lockoutEnabled }) => renderActive(lockoutEnabled)}
                        sorter={(a, b) => sortBoolean(a.lockoutEnabled, b.lockoutEnabled)}
                        title={t('APP:globals.status')}
                        width={80}
                        {...getFilterProps('lockoutEnabled')}
                    />
                    <Table.Column<User>
                        align="center"
                        key="actions"
                        render={(_, record) => (
                            <div className={styles.actions}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => setSelectedUser(record)}
                                    size="small"
                                    type="primary"
                                />
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => confirmRemoveUser(record)}
                                    size="small"
                                    type="primary"
                                />
                            </div>
                        )}
                        title={t('APP:globals.actions')}
                        width={120}
                    />
                </Table>
                <AddInstitute handleVisible={setInstituteModal} visible={instituteModal} />
                <AddUser handleVisible={setUserModal} institutes={institutes} visible={userModal} />
                <EditUser
                    institutes={institutes}
                    onCancel={() => setSelectedUser(undefined)}
                    userData={selectedUser}
                    visible={!!selectedUser}
                />
            </Card>
        </PageHeader>
    )
}

export default UsersManagement
