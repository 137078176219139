import React, { FunctionComponent, useRef, useState } from 'react'
import { Button, Card, Input, Modal, PageHeader, Table, Tag } from 'antd'
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { FilterDropdownProps } from 'antd/lib/table/interface'

import useInstitutes, { useDeleteInstitute } from 'shared/hooks/useInstitutes'
import { Institute } from 'shared/types'
import { getAllNames, sortAlphabetical, sortBoolean } from 'shared/utils/helpers'

import styles from './styles.module.less'
import AddInstitute from './components/AddInstitute'
import EditInstitute from './components/EditInstitute'

const InstitutesManagement: FunctionComponent = () => {
    const { i18n, t } = useTranslation(['TRANSLATION', 'APP'])
    const { data: institutes = [], isFetching: fetchingInstitutes } = useInstitutes()
    const { mutate: deletedInstitute } = useDeleteInstitute()
    const [instituteModal, setInstituteModal] = useState(false)
    const [editingInstitute, setEditingInstitute] = useState<Institute>()
    const searchInput: React.RefObject<Input> = useRef(null)

    function renderActive(isActive: boolean) {
        if (isActive) {
            return (
                <Tag color="success" style={{ margin: 0 }}>
                    {t('APP:globals.active')}
                </Tag>
            )
        }
        return (
            <Tag color="error" style={{ margin: 0 }}>
                {t('APP:globals.inactive')}
            </Tag>
        )
    }

    function generateFilters(propertyName: keyof Institute) {
        const allValues = getAllNames<Institute>(institutes, propertyName)
        if (allValues.length) {
            return allValues.map((key) => {
                const tKey = `APP:globals.${key}`
                return {
                    text: i18n.exists(tKey) ? t(tKey) : key,
                    value: key,
                }
            })
        }

        return []
    }

    function getFilterProps(dataIndex: keyof Institute) {
        return {
            filters: generateFilters(dataIndex),
            onFilter: (value: string | number | boolean, record: Institute) => {
                const recordValue = record[dataIndex]?.toString()
                if (recordValue) {
                    return recordValue.toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    function getColumnSearchProps(dataIndex: keyof Institute) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        onChange={(e) => {
                            setSelectedKeys(e ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm({ closeDropdown: false })}
                        ref={searchInput}
                        style={{ width: 190, marginBottom: 10, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        disabled={!institutes.length}
                        icon={<SearchOutlined />}
                        onClick={() => confirm({ closeDropdown: false })}
                        size="small"
                        style={{ marginRight: 10 }}
                        type="primary"
                    >
                        {t('TRANSLATION:global.search')}
                    </Button>
                    <Button
                        disabled={!institutes.length}
                        onClick={() => {
                            if (clearFilters) clearFilters()
                        }}
                        size="small"
                    >
                        {t('TRANSLATION:global.reset')}
                    </Button>
                </div>
            ),

            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#005fb2' : undefined }} />,

            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput?.current?.select(), 100)
                }
            },

            onFilter: (value: string | number | boolean, record: Institute) => {
                const recordValue = record[dataIndex]
                if (recordValue) {
                    return recordValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                return false
            },
        }
    }

    function confirmRemoveInstitute(id: string) {
        Modal.confirm({
            centered: true,
            cancelText: t('TRANSLATION:global.cancel'),
            okText: t('TRANSLATION:global.confirm'),
            onOk: () => deletedInstitute(id),
            title: t('APP:views.institutesManagement.warnings.confirmRemove', {
                name: id,
            }),
            content: t('APP:views.institutesManagement.warnings.contentRemove'),
        })
    }

    return (
        <PageHeader
            className="pageHeader-global"
            title={<h1 style={{ margin: 0 }}>{t('APP:components.menu.institutesManagement')}</h1>}
        >
            <Card
                bordered
                className="card-global"
                title={
                    <div>
                        <Button icon={<AppstoreAddOutlined />} onClick={() => setInstituteModal(true)} type="primary">
                            {t('APP:views.institutesManagement.labels.addInstituteBtn')}
                        </Button>
                    </div>
                }
            >
                <Table<Institute>
                    bordered
                    className={styles.tableWrapper}
                    dataSource={institutes}
                    loading={fetchingInstitutes}
                    rowKey="instituteId"
                    scroll={{ x: 'max-content', y: 'max-content' }}
                >
                    <Table.Column<Institute>
                        align="center"
                        dataIndex="instituteId"
                        key="instituteId"
                        render={(_, { instituteId }) => <Tag color="red">{instituteId}</Tag>}
                        sorter={(a, b) => sortAlphabetical(a.instituteId, b.instituteId)}
                        title={t('APP:globals.instituteId')}
                        width={80}
                    />
                    <Table.Column<Institute>
                        align="center"
                        dataIndex="institute"
                        key="institute"
                        render={(_, { institute }) => institute}
                        sorter={(a, b) => sortAlphabetical(a.institute, b.institute)}
                        title={t('APP:globals.institute')}
                        width={150}
                        {...getColumnSearchProps('institute')}
                    />
                    <Table.Column<Institute>
                        align="center"
                        dataIndex="isActive"
                        key="isActive"
                        render={(_, { isActive }) => renderActive(isActive)}
                        sorter={(a, b) => sortBoolean(a.isActive, b.isActive)}
                        title={t('APP:globals.status')}
                        width={80}
                        {...getFilterProps('isActive')}
                    />
                    <Table.Column<Institute>
                        align="center"
                        key="actions"
                        render={(_, record) => (
                            <div className={styles.actions}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => setEditingInstitute(record)}
                                    size="small"
                                    type="primary"
                                />
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => confirmRemoveInstitute(record.instituteId)}
                                    size="small"
                                    type="primary"
                                />
                            </div>
                        )}
                        title={t('APP:globals.actions')}
                        width={100}
                    />
                </Table>
                <AddInstitute handleVisible={setInstituteModal} visible={instituteModal} />
                <EditInstitute
                    instituteData={editingInstitute}
                    onCancel={() => setEditingInstitute(undefined)}
                    visible={!!editingInstitute}
                />
            </Card>
        </PageHeader>
    )
}

export default InstitutesManagement
