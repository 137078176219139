import React, { FunctionComponent } from 'react'
import { Alert, Form, Input, Modal, Select, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'

import { Institute } from 'shared/types'
import { useAddUser } from 'shared/hooks/useUsers'
import { userRoles } from 'shared/utils/keys'

type FormValues = {
    userName: string
    institute: string
    password: string
    role: string
}

interface Props {
    visible: boolean
    handleVisible: (visible: boolean) => void
    institutes: Institute[]
}

const formItemConfig = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14, offset: 2 },
}

const AddUser: FunctionComponent<Props> = ({ visible, handleVisible, institutes }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [form] = Form.useForm<FormValues>()
    const { mutate: addUser, isLoading, error, reset } = useAddUser()
    const rules: Rule[] = [{ required: true, message: t('TRANSLATION:validation.required') }]

    function handleOnCancel() {
        handleVisible(false)
    }

    function handleOnSubmit() {
        form.validateFields().then(async (values) => {
            reset()
            const reqBody = {
                userName: values.userName,
                institute: values.institute,
                password: values.password,
                role: [values.role],
                lockoutEnabled: true,
            }

            addUser(reqBody, {
                onSuccess: (data) => {
                    if (data.isSuccessful) {
                        form.resetFields()
                        handleOnCancel()
                    }
                },
            })
        })
    }

    return (
        <Modal
            cancelText={t('TRANSLATION:global.cancel')}
            centered
            destroyOnClose
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            okText={t('TRANSLATION:global.save')}
            onCancel={handleOnCancel}
            onOk={handleOnSubmit}
            title={t('APP:views.usersManagement.addUser.title')}
            visible={visible}
            width={400}
        >
            <div>
                <Form form={form} labelAlign="left" layout="horizontal" onFinish={handleOnSubmit}>
                    <Form.Item
                        label={t('APP:globals.username')}
                        name="userName"
                        rules={[...rules, { type: 'email', message: t('TRANSLATION:validation.typeEmail') }]}
                        {...formItemConfig}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={userRoles[1]}
                        label={t('APP:globals.role')}
                        name="role"
                        rules={rules}
                        {...formItemConfig}
                    >
                        <Select optionLabelProp="label">
                            {userRoles.map((role) => (
                                <Select.Option key={role} label={t(`APP:globals.${role}`)} value={role}>
                                    <Tag color="red">{t(`APP:globals.${role}`)}</Tag>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('APP:globals.institute')} name="institute" rules={rules} {...formItemConfig}>
                        <Select optionLabelProp="label">
                            {institutes.map((item) => (
                                <Select.Option key={item.instituteId} label={item.instituteId} value={item.instituteId}>
                                    <Tag color="red" style={{ margin: '0 20px 0 0' }}>
                                        {item.instituteId}
                                    </Tag>
                                    {item.institute}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        hasFeedback
                        label={t('APP:globals.password')}
                        name="password"
                        rules={[
                            ...rules,
                            {
                                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                message: t('TRANSLATION:validation.typePassword'),
                            },
                        ]}
                        {...formItemConfig}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
                {error && <Alert message={error?.message} type="error" />}
            </div>
        </Modal>
    )
}

export default AddUser
