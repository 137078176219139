import flatten from 'lodash/flatten'

import i18n from 'locales/i18n'

export function formatDate(date: Date, type?: 'short' | 'long'): string {
    const lang = i18n.language

    if (!date) {
        return i18n.t('TRANSLATION:global.errors.noDate')
    }

    return new Date(date).toLocaleDateString(lang, {
        year: 'numeric',
        month: type === 'long' ? 'short' : '2-digit',
        day: '2-digit',
    })
}

export function formatTime(date: Date): string {
    const lang = i18n.language

    if (!date) {
        return i18n.t('TRANSLATION:global.errors.noDate')
    }

    return new Date(date).toLocaleDateString(lang, {
        hour: '2-digit',
        minute: '2-digit',
    })
}

export function formatDateTime(date: Date, type?: 'short' | 'long', weekday?: 'short' | 'long'): string {
    const lang = i18n.language

    if (!date) {
        return i18n.t('TRANSLATION:global.errors.noDate')
    }

    return new Date(date).toLocaleDateString(lang, {
        year: 'numeric',
        month: type === 'long' ? 'short' : '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        weekday,
    })
}

export function sortAlphabetical(valueA: string | null, valueB: string | null) {
    if (!valueA) {
        return 1
    }

    if (!valueB) {
        return -1
    }

    if (valueA < valueB) {
        return -1
    }
    if (valueA > valueB) {
        return 1
    }
    return 0
}

export function sortNumber(valueA: number, valueB: number) {
    if (valueA < valueB) {
        return -1
    }
    if (valueA > valueB) {
        return 1
    }
    return 0
}

export function sortBoolean(valueA: boolean, valueB: boolean) {
    if (valueA < valueB) {
        return -1
    }
    if (valueA > valueB) {
        return 1
    }
    return 0
}

export function sortByDate(valueA: Date, valueB: Date) {
    const dateA = new Date(valueA).getTime()
    const dateB = new Date(valueB).getTime()
    if (dateA < dateB) {
        return -1
    }
    if (dateA > dateB) {
        return 1
    }
    return 0
}

export function getAllNames<T>(list: T[], propertyName: keyof T): string[] {
    const allRecords = list.reduce((acc: any[], recordB) => [...acc, recordB[propertyName]], [])
    const flattenAllNames = flatten(allRecords)

    return Array.from(new Set(flattenAllNames))
}

export function downloadBlob(blob: Blob, filename: string) {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob)

    // Create a new anchor element
    const a = document.createElement('a')

    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = url
    a.download = filename || 'download'

    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url)
            a.removeEventListener('click', clickHandler)
        }, 150)
    }

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false)

    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    // Comment out this line if you don't want an automatic download of the blob content
    a.click()

    // Return the anchor element
    // Useful if you want a reference to the element
    // in order to attach it to the DOM or use it in some other way
    return a
}

export function formatNumber(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
