import { extend } from 'umi-request'

const { REACT_APP_IDENTITY_URL, REACT_APP_USERAPI_URL } = process.env

const identitiyRequest = extend({
    prefix: REACT_APP_IDENTITY_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

const userDataRequest = extend({
    prefix: REACT_APP_USERAPI_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

export { identitiyRequest, userDataRequest }
