import React, { FunctionComponent } from 'react'
import { Alert, Button, Card, Tag } from 'antd'
import { MutationStatus } from 'react-query'
import { Trans, useTranslation } from 'react-i18next'

import QueryStatus from 'shared/components/QueryStatus'
import request from 'shared/api/request'
import { downloadBlob } from 'shared/utils/helpers'
import { UploadedFileInfo } from 'shared/hooks/useUploadMutation'

import styles from './styles.module.less'

interface Props {
    loading: boolean
    status: MutationStatus
    fileReport?: UploadedFileInfo
    error?: string
    isError: boolean
    isSuccess: boolean
}

const Status: FunctionComponent<Props> = ({ loading, status, fileReport, error, isError, isSuccess }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])

    function handleOnClick() {
        if (fileReport) {
            const documentId = fileReport.id
            request
                .get(`sil/logfile/${documentId}`)
                .then((response) => {
                    if (response) {
                        const indexOfDot = fileReport.csvFileName.lastIndexOf('.')
                        const filename = [
                            fileReport.csvFileName.slice(0, indexOfDot),
                            '-log',
                            fileReport.csvFileName.slice(indexOfDot),
                        ].join('')
                        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' })

                        downloadBlob(blob, filename)
                    }
                })
                .catch((err) => console.log('ERROR FROM DOWNLOAD LOG FILE', err))
        }
    }

    const fileName = fileReport?.csvFileName
    const okRecords = fileReport?.ok
    const errorsRecords = fileReport?.errors
    return (
        <Card loading={loading} type="inner">
            <div className={styles.title}>
                <h2>{t('APP:globals.status')}:</h2>
                <QueryStatus status={status} />
            </div>
            <div style={{ margin: '20px 0' }}>
                {isError && <Alert message={t(`TRANSLATION:requestErrors.${error}`)} showIcon type="error" />}
                {isSuccess && (
                    <Alert
                        description={
                            <div className={styles.successInfo}>
                                <Trans i18nKey="APP:views.uploadFile.status.successMsg">
                                    Date <Tag color="blue">{{ fileName }}</Tag> was uploaded successfully.
                                    <Tag color="blue">{{ okRecords }}</Tag> numbers were successfully imported.
                                    <Tag color="error">{{ errorsRecords }}</Tag> errors were detected. You can download
                                    the upload log by clicking the button below.
                                </Trans>
                                <Button disabled={!fileReport} loading={loading} onClick={handleOnClick} type="primary">
                                    {t('APP:views.uploadFile.status.downloadBtn')}
                                </Button>
                            </div>
                        }
                        message={t('TRANSLATION:global.success')}
                        showIcon
                        type="success"
                    />
                )}
            </div>
        </Card>
    )
}

export default Status
