export const settings = {
    defaultLang: 'de-DE',
    fallbackLang: 'de-DE',
}

export const locales = [
    {
        prefix: 'de-DE',
        name: 'Deutsch',
    },
    {
        prefix: 'en-US',
        name: 'English',
    },
]
