import React, { FunctionComponent, useEffect, useRef } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { focusManager } from 'react-query'
import { Spin } from 'antd'

import routes from 'config/routes'
import { SilRoute } from 'shared/types'
import { LocalStorageKeys } from 'shared/utils/keys'
import useUser from 'shared/hooks/useUser'
import useLogin from 'shared/hooks/useLogin'

import PrivateRoute from './components/PrivateRoute'
import Layout from './components/Layout'
import { de, en } from './locales'

const MAX_INACTIVITY_TIME = 1200 // 20 minutes
const MAX_TOKEN_VALIDITY_TIME = 2 * 60 * 60 * 1000 // 2 hours

interface Props extends RouteComponentProps {}
const App: FunctionComponent<Props> = ({ location, history }) => {
    const { i18n } = useTranslation('APP')
    i18n.addResourceBundle('en-US', 'APP', en)
    i18n.addResourceBundle('de-DE', 'APP', de)
    const wasLogged = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) !== null
    const authorityOfUser = localStorage.getItem(LocalStorageKeys.AUTHORITY)
    const expiresIn = localStorage.getItem(LocalStorageKeys.EXPIRES_IN)
    const intervalRef = useRef<number>()
    const intervalCounter = useRef<number>(0)

    const { userStore } = useUser()
    const { logout } = useLogin()

    function handleFocus() {
        const isFocused = focusManager.isFocused()

        if (!isFocused) {
            intervalRef.current = window.setInterval(() => {
                if (intervalCounter.current >= MAX_INACTIVITY_TIME) {
                    logout()
                    window.clearInterval(intervalRef.current)
                } else {
                    intervalCounter.current += 1
                }
                console.log(
                    `You will be automatically logged out of the application in ${
                        MAX_INACTIVITY_TIME - intervalCounter.current
                    } seconds`
                )
            }, 1000)
        } else {
            intervalCounter.current = 0
            window.clearInterval(intervalRef.current)
        }
    }

    useEffect(() => {
        // focusManager.setEventListener(() => {
        //     // Listen to visibillitychange and focus
        //     if (typeof window !== 'undefined' && window.addEventListener) {
        //         console.log(1, 'SET EVENT')
        //         window.addEventListener('visibilitychange', handleFocus, false)
        //         // window.addEventListener('focus', handleFocus, false)
        //     }

        //     return () => {
        //         // Be sure to unsubscribe if a new handler is set
        //         console.log(2, 'REMOVE EVENT')

        //         window.removeEventListener('visibilitychange', handleFocus)
        //         // window.removeEventListener('focus', handleFocus)
        //     }
        // })

        if (expiresIn) {
            const parsedExpiresIn = new Date(expiresIn).valueOf()
            const now = new Date().valueOf()

            if (now > parsedExpiresIn && now - parsedExpiresIn > MAX_TOKEN_VALIDITY_TIME) {
                console.log(2, 'LOGOUT BECAUSE OF TOKEN VALIDATION TIME')
                logout()
                history.push('/login')
            }
        }

        if (typeof window !== 'undefined' && window.addEventListener) {
            console.log('SET VISIBILITY EVENT')
            window.addEventListener('visibilitychange', handleFocus, false)
            // window.addEventListener('focus', handleFocus, false)
        }

        return () => {
            // Be sure to unsubscribe on unmount
            console.log(2, 'REMOVE VISIBILITY EVENT')

            window.removeEventListener('visibilitychange', handleFocus)
            // window.removeEventListener('focus', handleFocus)
        }
    }, [])

    function renderRoute(route: SilRoute) {
        const { authority } = route
        if (authority) {
            return (
                <PrivateRoute
                    authority={authority}
                    component={route.component}
                    exact={route.exact || false}
                    key={route.name}
                    name={route.name}
                    path={route.path}
                />
            )
        }
        return <Route component={route.component} exact={route.exact || false} key={route.name} path={route.path} />
    }

    if (!userStore?.isLogged && !wasLogged) {
        console.log('REDIRECTING')
        return (
            <Redirect
                // to={{
                //     pathname: '/login',
                //     state: { from: location.pathname },
                // }}
                to="/login"
            />
        )
    }

    if (authorityOfUser) {
        const parsedAuthority: string[] = JSON.parse(authorityOfUser)
        const isProperUser = parsedAuthority.some((item) => item.indexOf('SIL') >= 0)
        if (!isProperUser) {
            console.log('Logout Unauthorized user')
            logout()

            return (
                <Redirect
                    // to={{
                    //     pathname: '/login',
                    //     state: { from: location.pathname },
                    // }}
                    to="/login"
                />
            )
        }
    }
    console.log(2, 'RENDER APP')
    return userStore?.user ? (
        <Layout path={location.pathname}>
            <Switch>{routes.map((item) => renderRoute(item))}</Switch>
        </Layout>
    ) : (
        <Spin />
    )
}

export default App
