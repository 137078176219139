import React from 'react'
import {
    BankOutlined,
    DownloadOutlined,
    HomeOutlined,
    UploadOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons'

import { SilRoute } from 'shared/types'
import Homepage from 'views/App/views/Homepage'
import UploadFile from 'views/App/views/UploadFile'
import DownloadFile from 'views/App/views/DownloadFile'
import UserDashboard from 'views/App/views/UserDashboard'
import NotFound from 'views/App/views/NotFound'
import UsersManagement from 'views/App/views/UsersManagement'
import InstitutesManagement from 'views/App/views/InstitutesManagement'

const routes: SilRoute[] = [
    {
        name: 'homepage',
        path: '/',
        icon: React.createElement(HomeOutlined),
        component: Homepage,
        exact: true,
    },
    {
        name: 'uploadfile',
        path: '/uploadfile',
        icon: React.createElement(UploadOutlined),
        component: UploadFile,
    },
    {
        name: 'downloadfile',
        path: '/downloadfile',
        icon: React.createElement(DownloadOutlined),
        component: DownloadFile,
    },
    {
        name: 'user',
        path: '/user',
        icon: React.createElement(UserOutlined),
        component: UserDashboard,
    },
    {
        name: 'usersManagement',
        path: '/users',
        icon: React.createElement(UserSwitchOutlined),
        component: UsersManagement,
        authority: ['SILAdmin'],
    },
    {
        name: 'institutesManagement',
        path: '/institutes',
        icon: React.createElement(BankOutlined),
        component: InstitutesManagement,
        authority: ['SILAdmin'],
    },
    {
        name: 'notFound',
        path: '*',
        component: NotFound,
        hideInMenu: true,
    },
]

export default routes
