import { useQuery, UseQueryResult } from 'react-query'

import request from 'shared/api/request'
import { InstituteStats } from 'shared/types'

type FetchResult = {
    isSuccessful: boolean
    value: InstituteStats[]
    message: string | null
    exceptionDetails: string | null
}

async function fetchInstituteStats(): Promise<InstituteStats[]> {
    const result = await request
        .get('sil/institutes/share')
        .then(({ isSuccessful, value }: FetchResult) => {
            if (isSuccessful) {
                return value
            }
            return []
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

export default function useStatistic(): UseQueryResult<InstituteStats[]> {
    return useQuery('instituteStats', fetchInstituteStats, { staleTime: 3600000 })
}
