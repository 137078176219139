import { useQuery, UseQueryResult } from 'react-query'

import request from 'shared/api/request'
import { UploadedFile } from 'shared/types'

type FetchResult = {
    isSuccessful: boolean
    value: UploadedFile[]
    message: string | null
    exceptionDetails: string | null
}

async function fetchUploadedFiles(): Promise<UploadedFile[]> {
    const result = await request
        .get('sil/silHistory')
        .then(({ isSuccessful, value }: FetchResult) => {
            if (isSuccessful) {
                return value
            }
            return []
        })
        .catch((err) => {
            throw new Error(err.message)
        })

    return result
}

export default function useUploadList(): UseQueryResult<UploadedFile[]> {
    return useQuery('uploadedFiles', fetchUploadedFiles, { staleTime: 3600000 })
}
