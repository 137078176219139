import { useQuery, useQueryClient } from 'react-query'
import JwtDecode from 'jwt-decode'

import request from 'shared/api/request'
import { LoginUser, RequestResponse } from 'shared/types'
import { LocalStorageKeys } from 'shared/utils/keys'

export interface StateType {
    isLogged: boolean
    user?: LoginUser
}
interface RoleResult extends RequestResponse {
    value: {
        id: string
        userName: string
        institute: string
        role: string[]
        lockoutEnabled: boolean
    }
}

interface InstituteResult extends RequestResponse {
    value: {
        institute: string
    }
}

type JwtLoginUser = {
    userId: string
}

async function fetchRole(id: string): Promise<RoleResult> {
    return request.get(`Users/${id}/details`)
}

async function fetchInstitute(instituteId: string): Promise<InstituteResult> {
    return request.get(`sil/institutes/${instituteId}`)
}

async function getUserNameData() {
    try {
        const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) || ''
        const userJwtData = JwtDecode<JwtLoginUser>(token)
        //        console.log('JWT', userJwtData)
        const { value: roleValue } = await fetchRole(userJwtData.userId)
        const { value: instituteValue } = await fetchInstitute(roleValue.institute)
        const isAdmin = roleValue.role.includes('SILAdmin')
        const userData: LoginUser = {
            ...roleValue,
            instituteName: instituteValue.institute,
            isAdmin,
        }

        localStorage.setItem(LocalStorageKeys.AUTHORITY, JSON.stringify(roleValue.role))
        console.log('USER', userData)
        return { isLogged: true, user: userData }
    } catch (error) {
        throw new Error(`${error.message} / ${error.exceptionDetails}`)
    }
}

export default function useMe() {
    const queryClient = useQueryClient()
    // const catchedUser = queryClient.getQueryData<StateType>('user') || { user: undefined, isLogged: false }
    const { data: userStore } = useQuery<StateType, never>('user', getUserNameData, {
        onSuccess: (data) => {
            if (data.isLogged) {
                queryClient.invalidateQueries('downloadDate')
                queryClient.invalidateQueries('instituteStats')
            }
        },
        // notifyOnChangeProps: ['data'],
        // retry: false,
        // initialData: { user: undefined, isLogged: false },
        staleTime: 3600000,
        // refetchOnMount: true,
    })

    return { userStore }
}
