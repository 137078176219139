import React, { FunctionComponent, useEffect } from 'react'
import { Alert, Checkbox, Form, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'
import { isEqual } from 'lodash'

import { Institute } from 'shared/types'
import { useEditInstitute } from 'shared/hooks/useInstitutes'

type FormValues = {
    instituteId: string
    institute: string
    isActive: boolean
}

interface Props {
    visible: boolean
    instituteData?: Institute
    onCancel: () => void
}

const formItemConfig = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14, offset: 2 },
}

const EditInstitute: FunctionComponent<Props> = ({ visible, instituteData, onCancel }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [form] = Form.useForm<FormValues>()
    const { mutate: editInstitute, isLoading, error, reset } = useEditInstitute()
    const rules: Rule[] = [{ required: true, message: t('TRANSLATION:validation.required') }]
    const idRules: Rule[] = [
        { pattern: /^[a-zA-Z]+$/, message: t('TRANSLATION:validation.onlyLetters') },
        { min: 3, message: t('TRANSLATION:validation.minCharacters', { min: 3 }) },
        { max: 4, message: t('TRANSLATION:validation.maxCharacters', { max: 4 }) },
    ]
    function handleOnSubmit() {
        form.validateFields().then((values) => {
            reset()

            if (instituteData) {
                const initialValues = {
                    ...instituteData,
                }

                const reqBody = {
                    ...values,
                }

                if (isEqual(initialValues, reqBody)) {
                    message.warning(t('TRANSLATION:global.errors.noChanges'))
                } else {
                    editInstitute(reqBody)
                    onCancel()
                }
            }
        })
    }

    useEffect(() => {
        if (instituteData) {
            form.setFieldsValue(instituteData)
        }
    }, [instituteData])

    return (
        <Modal
            cancelText={t('TRANSLATION:global.cancel')}
            centered
            destroyOnClose
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            okText={t('TRANSLATION:global.save')}
            onCancel={onCancel}
            onOk={handleOnSubmit}
            title={t('APP:views.institutesManagement.editInstitute.title', { name: instituteData?.instituteId || '' })}
            visible={visible}
            width={400}
        >
            <div>
                <Form form={form} labelAlign="left" layout="horizontal" onFinish={handleOnSubmit}>
                    <Form.Item
                        label={t('APP:globals.instituteId')}
                        name="instituteId"
                        rules={[...rules, ...idRules]}
                        {...formItemConfig}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label={t('APP:globals.institute')} name="institute" rules={rules} {...formItemConfig}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('APP:globals.active')}
                        name="isActive"
                        valuePropName="checked"
                        {...formItemConfig}
                    >
                        <Checkbox />
                    </Form.Item>
                </Form>
                {error && <Alert message={error?.message} type="error" />}
            </div>
        </Modal>
    )
}

export default EditInstitute
