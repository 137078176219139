import React, { FunctionComponent } from 'react'
import { Alert, Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Rule } from 'antd/lib/form'

import { useAddInstitute } from 'shared/hooks/useInstitutes'

type FormValues = {
    name: string
    key: string
}

interface Props {
    visible: boolean
    handleVisible: (visible: boolean) => void
}

const formItemConfig = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14, offset: 2 },
}

const AddInstitute: FunctionComponent<Props> = ({ visible, handleVisible }) => {
    const { t } = useTranslation(['TRANSLATION', 'APP'])
    const [form] = Form.useForm<FormValues>()
    const { mutate: addInstitute, isLoading, error, reset } = useAddInstitute()
    const nameRules: Rule[] = [{ required: true, message: t('TRANSLATION:validation.required') }]
    const keyRules: Rule[] = [
        { required: true, message: t('TRANSLATION:validation.required') },
        { pattern: /^[a-zA-Z]+$/, message: t('TRANSLATION:validation.onlyLetters') },
        { min: 3, message: t('TRANSLATION:validation.minCharacters', { min: 3 }) },
        { max: 4, message: t('TRANSLATION:validation.maxCharacters', { max: 4 }) },
    ]

    function handleOnCancel() {
        handleVisible(false)
    }

    function handleOnSubmit() {
        form.validateFields().then(async (values) => {
            reset()
            const reqBody = {
                institute: values.name,
                instituteId: values.key.toUpperCase(),
                IsActive: true,
            }

            addInstitute(reqBody, {
                onSuccess: (data) => {
                    if (data.isSuccessful) {
                        form.resetFields()
                        handleOnCancel()
                    }
                },
            })
        })
    }

    return (
        <Modal
            cancelText={t('TRANSLATION:global.cancel')}
            centered
            destroyOnClose
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            okText={t('TRANSLATION:global.save')}
            onCancel={handleOnCancel}
            onOk={handleOnSubmit}
            title={t('APP:views.institutesManagement.addInstitute.title')}
            visible={visible}
            width={400}
        >
            <div>
                <Form form={form} labelAlign="left" layout="horizontal" onFinish={handleOnSubmit}>
                    <Form.Item label={t('APP:globals.name')} name="name" rules={nameRules} {...formItemConfig}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('APP:globals.instituteId')} name="key" rules={keyRules} {...formItemConfig}>
                        <Input />
                    </Form.Item>
                </Form>
                {error && <Alert message={error?.message} type="error" />}
            </div>
        </Modal>
    )
}

export default AddInstitute
