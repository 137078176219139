import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.less'

interface Props {
    logout: () => void
}

export default function UserMenu({ logout }: Props): JSX.Element {
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
    const { t } = useTranslation('APP')

    return (
        <Dropdown
            onVisibleChange={() => setDropdownVisible(!dropdownVisible)}
            overlay={
                <ul className={styles.user_menu}>
                    <li className={styles.user_menuItem}>
                        <Link onClick={() => setDropdownVisible(false)} to="/user">
                            <SettingOutlined />
                            <span>{t('components.header.profileMenu.settings')}</span>
                        </Link>
                    </li>
                    <li className={styles.user_menuItem}>
                        <button onClick={() => logout()} type="button">
                            <LogoutOutlined />
                            <span>{t('components.header.profileMenu.logout')}</span>
                        </button>
                    </li>
                </ul>
            }
            placement="bottomRight"
            trigger={['click']}
            visible={dropdownVisible}
        >
            <button className={styles.headerBtn} type="button">
                <UserOutlined className={styles.headerBtn_avatar} />
                {/* <span className={styles.headerBtn_label}>{label || t('header.profileMenu.label')}</span> */}
                {dropdownVisible ? (
                    <CaretUpOutlined className={styles.headerBtn_caret} />
                ) : (
                    <CaretDownOutlined className={styles.headerBtn_caret} />
                )}
            </button>
        </Dropdown>
    )
}
