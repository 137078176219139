import { useMutation, useQueryClient } from 'react-query'
import { ResponseError } from 'umi-request'

import { identitiyRequest } from 'shared/api/authRequest'
import { LocalStorageKeys } from 'shared/utils/keys'
import { LoginUser } from 'shared/types'

export interface StateType {
    isLogged: boolean
    user?: LoginUser
}

export type LoginParamsType = {
    userName: string
    password: string
}

type LoginResult = {
    expires_in: number
    access_token: string
    refresh_token: string
}

// interface RoleResult extends RequestResponse {
//     value: {
//         user: {
//             userName: string
//             institute: string
//         }

//         roles: [
//             {
//                 name: 'string'
//             }
//         ]
//     }
// }

type LoginErrorResult = {
    error: string
    error_description: string
}

// type JwtLoginUser = {
//     sub: string
// }

const { REACT_APP_ASPNET_IDENTITY_SECRET } = process.env
async function loginService(params: LoginParamsType): Promise<LoginResult> {
    const sendData = `grant_type=password&username=${params.userName}&password=${encodeURIComponent(
        params.password
    )}&scope=Link.OptiMACH.Scope offline_access&client_id=Link.Alpha.OptiMach.Web&client_secret=${REACT_APP_ASPNET_IDENTITY_SECRET}`

    return identitiyRequest.post('connect/token', { data: sendData })
}

// async function roleService(id: string): Promise<RoleResult> {
//     return request.get(`Users/${id}/roles`)
// }

async function loginUser(loginData: LoginParamsType) {
    try {
        const response = await loginService(loginData)

        if (response) {
            console.log('🚀 ~ file: useLogin.ts ~ line 63 ~ loginUser ~ response', response)
            // const userJwtData = JwtDecode<JwtLoginUser>(response.access_token)
            const expirationDate = new Date(new Date().getTime() + response.expires_in * 1000)
            localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, response.access_token)
            localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, response.refresh_token)
            localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)
            return { user: undefined, isLogged: true }
            // const roleResponse = await roleService(userJwtData.sub)
            // const { value, isSuccessful } = roleResponse
            // if (isSuccessful) {
            //     const role = value.roles.map((item) => item.name)
            //     const userData: LoginUser = {
            //         ...value.user,
            //         role,
            //         sub: userJwtData.sub,
            //     }

            //     localStorage.setItem(LocalStorageKeys.AUTHORITY, JSON.stringify(role))
            //     console.log('USER', userData)
            //     return { user: userData, isLogged: true }
            // }
        }
        return { user: undefined, isLogged: false }
    } catch (err) {
        const errData: LoginErrorResult = { ...err.data }
        throw new Error(`${errData.error} / ${errData.error_description}`)
    }
}

async function logoutUser() {
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN)
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN)
    localStorage.removeItem(LocalStorageKeys.EXPIRES_IN)
    localStorage.removeItem(LocalStorageKeys.AUTHORITY)
}

export default function useLogin() {
    const queryClient = useQueryClient()
    const user = queryClient.getQueryData<StateType>('user') || { user: undefined, isLogged: false }

    const { mutate: login, error, isError, isLoading } = useMutation<
        StateType,
        ResponseError<LoginErrorResult>,
        LoginParamsType
    >((loginData) => loginUser(loginData), {
        onSuccess: (data) => {
            queryClient.setQueryData<StateType>('user', {
                ...user,
                ...data,
            })
            queryClient.invalidateQueries('user')
        },
        onError: () => {
            queryClient.setQueryData<StateType>('user', {
                ...user,
                user: undefined,
                isLogged: false,
            })
        },
    })

    const { mutate: logout } = useMutation(logoutUser, {
        onSuccess: () => {
            queryClient.setQueryData<StateType>('user', {
                ...user,
                user: undefined,
                isLogged: false,
            })
        },
    })
    return { user, login, logout, error, isError, isLoading }
}

// User
// kai.wasternack@link.ch
// wGKMlH7q.

// sil@link.ch
// Link4All$
