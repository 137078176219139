import React, { FunctionComponent, useState } from 'react'
import { Card, Col, PageHeader, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import useUploadMutation, { UploadedFileInfo, useNoUploadMutation } from 'shared/hooks/useUploadMutation'
import useUploadList from 'shared/hooks/useUploadList'
import useMe from 'shared/hooks/useUser'

import UploadComponent from './components/Upload'
import UploadList from './components/List'
import Status from './components/Status'

const UploadFile: FunctionComponent = () => {
    const { t } = useTranslation('APP')
    const [fileReport, setFileReport] = useState<UploadedFileInfo>()
    const {
        mutate,
        isError,
        isLoading: uploadLoading,
        isSuccess,
        error,
        status: uploadStatus,
        reset,
    } = useUploadMutation()

    const {
        mutate: sendNoUpload,
        isLoading: noUploadLoading,
        error: noUploadError,
        isError: noUploadIsError,
    } = useNoUploadMutation()
    const { userStore } = useMe()
    const { data: uploadedList = [], isFetching } = useUploadList()
    const lastUpdate = uploadedList.length ? uploadedList[0].upDownDate : undefined

    return (
        <PageHeader className="pageHeader-global" title={<h1 style={{ margin: 0 }}>{t('views.uploadFile.title')}</h1>}>
            <Card bordered className="card-global">
                <Row
                    gutter={[
                        { xs: 0, sm: 0, md: 0, lg: 20 },
                        { xs: 20, sm: 20, md: 50, lg: 50 },
                    ]}
                    justify="center"
                >
                    <Col xs={{ span: 24 }} xxl={{ span: 15 }}>
                        <Card loading={isFetching} type="inner">
                            <UploadComponent
                                lastUploadDate={lastUpdate}
                                loading={uploadLoading || noUploadLoading}
                                onNoUpload={sendNoUpload}
                                onResetQuery={reset}
                                onUpdateReport={setFileReport}
                                onUpload={mutate}
                            />
                        </Card>
                    </Col>
                    {(uploadStatus === 'error' || uploadStatus === 'success') && (
                        <Col xs={{ span: 24 }} xxl={{ span: 15 }}>
                            <Status
                                error={error?.message || noUploadError?.message}
                                fileReport={fileReport}
                                isError={isError || noUploadIsError}
                                isSuccess={isSuccess}
                                loading={uploadLoading}
                                status={uploadStatus}
                            />
                        </Col>
                    )}
                    {userStore?.user?.isAdmin ? (
                        <Col span={24}>
                            <UploadList isFetching={isFetching} list={uploadedList} />
                        </Col>
                    ) : null}
                </Row>
            </Card>
        </PageHeader>
    )
}

export default UploadFile
