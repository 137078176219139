import React, { FunctionComponent } from 'react'
import { Card, PageHeader } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'

import useMe from 'shared/hooks/useUser'

import styles from './styles.module.less'
import Info from './components/Info'
import EditForm from './components/EditForm'

const UserDashboard: FunctionComponent = () => {
    const { t } = useTranslation('APP')
    const { userStore } = useMe()

    return (
        <PageHeader className={`${styles.wrapper} pageHeader-global`} title={t('APP:views.userDashboard.title')}>
            <Card
                bordered
                className={styles.card}
                title={
                    <div className={styles.cardTitle}>
                        <InfoCircleOutlined />
                        <h3>
                            <Trans i18nKey="APP:views.userDashboard.info">
                                If the SIL user changes within the institute or if a SIL user leaves, the SIL admin must
                                be informed at <a href="mailto:sil@link.ch">sil@link.ch</a> so that the user can be
                                deleted.
                            </Trans>
                        </h3>
                    </div>
                }
            >
                <div>
                    <Info user={userStore?.user} />
                    <EditForm user={userStore?.user} />
                </div>
            </Card>
        </PageHeader>
    )
}

export default UserDashboard
